import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from "./plugins/i18n";
import moment from 'moment';
import vuetify from './plugins/vuetify';
import VueGtag from "vue-gtag";
import logger from './plugins/logger';
require('./plugins/general.scss')

moment.locale(i18n.locale)
Vue.prototype.moment = moment

// Vuetify's CSS styles 
import 'vuetify/dist/vuetify.min.css'

Vue.config.productionTip = false

console.log('start-track',process.env.VUE_APP_GA_TRACK_ID)
Vue.use(VueGtag, {
  config: { 
    id: process.env.VUE_APP_GA_TRACK_ID, 
    params: {
      send_page_view: true
    }
  }
});

import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
  load: {
    key:  process.env.VUE_APP_MAPS_GEO_API_KEY,
    libraries: 'places',
  },
})



new Vue({
  router,
  i18n,
  store,
  moment,
  vuetify,
  logger,
  render: h => h(App)
}).$mount('#app')