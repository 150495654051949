import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from "@/plugins/i18n";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/map/stores',
    name: 'stores',
    component: () => import('../views/Stores.vue')
  },
  {
    path: "/mgm/:code",
    params: 'code',
    name: 'mgm',
    component: () => import('../views/Mgm/code.vue')
  },
  {
    path: "/campaign/:code",
    params: 'code',
    name: 'campaign',
    component: () => import('../views/Campaign/code.vue')
  },
  {
    path: "/download/:slug",
    params: 'slug',
    name: 'download',
    component: () => import('../views/Home.vue')
  },
  {
    path: "/qrcode",
    name: 'qrcode',
    component: () => import('../views/QrCode/code.vue')
  },
  {
    path: "/view-coupon/:code",
    params: 'code',
    name: 'coupon',
    component: () => import('../views/QrCode/coupon.vue')
  },
  {
    path: "/qr",
    name: 'qr',
    redirect: 'qrcode'
  },
  {
    path: '/page/:slug',
    name: 'pages',
    params: 'slug',
    component: () => import('../views/Pages/page.vue')
  },
  {
    path: "/openapp",
    name: "openapp",
    component: () => import('../views/Home.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {

  console.log('to',to)
  console.log('from',from)

  

  let parentsRoute = to.matched
  to.meta.breadCrumbs = []
  to.meta.analytics = to.name

  parentsRoute.forEach(parent => {
    to.meta.breadCrumbs.push({text:parent.name,href:''+parent.path})

    var title = i18n.t('router.'+parent.name+'.title');
    var description = i18n.t('router.'+parent.name+'.description');

    if(to.params){
      if(to.params.slug){
         title = i18n.t('router.'+parent.name+'.'+to.params.slug+'.title');
         description = i18n.t('router.'+parent.name+'.'+to.params.slug+'.description');
      }
    }

    if(parent.name == "openapp"){
      console.log("OPEN APP "+ process.env.VUE_APP_DEEP_LINK)
      window.location.replace( process.env.VUE_APP_DEEP_LINK )
    }

    document.title = title
    document.description = description;
  });

  var metaThemeColor = document.createElement('meta');
    metaThemeColor.setAttribute('name', 'theme-color');
    metaThemeColor.setAttribute('id', 'theme-color');
    metaThemeColor.content = "#005071";

  document.getElementsByTagName('head')[0].appendChild(metaThemeColor);

  next()

})

export default router
