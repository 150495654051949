<template>
  <v-app id="app">
    <v-main>
      <navbar />
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import navbar from "@/components/layout/navbar";
export default {
  name: 'App',
  components:{
    navbar
  },
  data: () => ({
    //
  }),
  mounted(){

    var lastdate = new Date("2023-06-01");
    var today = new Date();

    console.log('check date lastdate', lastdate)
    console.log('check date today', today)
    console.log('check date', (today >= lastdate ))

    // REDIRECT TO VENCHI COM AFTER 1 JUNE 2023
    if(today >= lastdate ){
      window.location.href = 'https://venchi.com';
    }

  }
};
</script>