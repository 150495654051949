import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import 'material-design-icons-iconfont/dist/material-design-icons.css' // Ensure you are using css-loader

// import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
     theme: {
          themes: {
             light: {
               primary: "#005071",
               secondary: "#E77822", // #BE9949
               light: "#FFFFFF", // #FFFFFF
             },
          },
     },
     icons: {
      iconfont: 'md',
     },
});
