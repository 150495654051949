import apiClient from '@/plugins/apiClient'
const qs = require('qs');

export default {
     namespaced: true,
     state:{
          stores: [],
          store: null,
          loading: null,
          meta: {
               current_page: 1,
               total: 0,
               per_page: 12
          },
     },
     getters:{
        stores: state => state.stores,
        store: state => state.store,
        loading: state => state.loading,
        meta: state => state.meta,
     },
     mutations:{
          setStores(state,payload){
               console.log('MUTATION setStores',payload);
               state.stores = payload.data.data;
               // state.meta = payload.data.meta;
          },
          setLoading(state, val){
               state.loading = val
          },
          error(err){
               console.log("MUTAITON ERRORR", err)
          }
     },
     actions:{
          getStores({commit,state}, params){
               commit('setLoading', true);
               return new Promise((resolve, reject) => {
                   console.log('Get Stores page='+state.meta.current_page, params)

                   params = (params) ? qs.stringify(params, { arrayFormat: 'indices' }) : null
                   
                   apiClient.get("stores/?page="+state.meta.current_page+"&"+params).then(resp => {
                       resolve(resp);
                       commit('setLoading', false);
                       commit('setStores', resp);
                   })
                   .catch(err => {
                       console.log('Get Stores page ERRROR', err)
                       reject(err);
                       commit('setLoading', false);
                       commit('error', err);
                   });
               })
           },
     }
}