import Vue from 'vue'
import Vuex from 'vuex'
import loading from "@/store/modules/loading";
import snackBars from "@/store/modules/snackBars";
import pages from "@/store/modules/pages";
import stores from "@/store/modules/stores";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    loading,
    snackBars,
    pages,
    stores
  }
})
