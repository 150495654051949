import Vue from 'vue'
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);

import { languages } from '@/locales/index.js'
import { defaultLocale } from '@/locales/index.js'

const messages = Object.assign(languages)

const i18n = new VueI18n({
     locale: defaultLocale,
     fallbackLocale: 'it',
     messages,
     silentTranslationWarn: true
})

export default i18n