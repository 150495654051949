<template>
     <div fluid >
         <v-app-bar
          elevation="4"
          color="light"
          >
               <div class="d-block d-md-none">
                    <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
               </div>
               <v-spacer></v-spacer>
               <router-link  :to="{name:'home'}">
                    <v-img class="logo mx-auto" height="40px" contain :src="require('@/assets/logo.png')"></v-img>
               </router-link>
               <v-spacer></v-spacer>
               <div class="d-md-block d-sm-none">
                    <v-btn plain :to="{name:'pages',params:{slug:'rules'}}">{{$t('router.pages.rules.title')}}</v-btn>
                    <v-btn plain :to="{name:'pages',params:{slug:'contact'}}">{{$t('router.pages.contact.title')}}</v-btn>
                    <v-btn plain :to="{name:'pages',params:{slug:'privacy-policy'}}">{{$t('router.pages.privacy-policy.title')}}</v-btn>
                    <v-btn plain target="_blank" :href="$t('router.news.link')"> <v-icon>mdi-newspaper</v-icon> {{$t('router.news.title')}}</v-btn>
                    <v-btn plain :to="{name:'stores'}"><v-icon>mdi-map-marker-radius</v-icon> {{$t('router.stores.title')}}</v-btn> 

               </div>
               <v-spacer></v-spacer>
               <locales-nav></locales-nav>

          </v-app-bar>
          <v-navigation-drawer
                         v-model="drawer"
                         absolute
                         width="90%"
                    >
                     <router-link  :to="{name:'home'}">
                         <v-img class="logo mx-auto my-4" height="40px" contain :src="require('@/assets/logo.png')"></v-img>
                     </router-link>
                         <v-list
                         nav
                         dense
                         >
                         <v-list-item-group>
                              <v-list-item>
                              <v-btn plain :to="{name:'pages',params:{slug:'rules'}}">{{$t('router.pages.rules.title')}}</v-btn>
                              </v-list-item>
                              <v-list-item>
                              <v-btn plain :to="{name:'pages',params:{slug:'contact'}}">{{$t('router.pages.contact.title')}}</v-btn>
                               </v-list-item>
                              <v-list-item>
                              <v-btn plain :to="{name:'pages',params:{slug:'privacy-policy'}}">{{$t('router.pages.privacy-policy.title')}}</v-btn>
                               </v-list-item>
                              <v-list-item>
                              <v-btn plain target="_blank" :href="$t('router.news.link')"> <v-icon>mdi-newspaper</v-icon> {{$t('router.news.title')}}</v-btn>
                              </v-list-item>
                              <v-list-item>
                              <v-btn plain :to="{name:'stores'}"><v-icon>mdi-map-marker-radius</v-icon> {{$t('router.stores.title')}}</v-btn> 
                              </v-list-item>
                              <v-list-item>
                                   <locales-nav></locales-nav>
                              </v-list-item>
                         </v-list-item-group>
                         </v-list>
                    </v-navigation-drawer>
          <v-progress-linear v-if="loading" :indeterminate="loading"></v-progress-linear>
          <snack-bars></snack-bars>
     </div>
</template>

<script>
import LocalesNav from "@/components/layout/LocalesNav"
import SnackBars from "@/components/layout/SnackBars"
import {mapGetters} from "vuex";

export default {
  name: 'navbar',
  components:{
       "locales-nav": LocalesNav,
       "snack-bars": SnackBars
  },
  computed: {
     ...mapGetters({
          loading: 'loading/loading',
      }),
  },
  data: () => ({
    drawer: false
  }),
};
</script>

<style scoped>
.v-btn--active{
     border-bottom: 2px solid #005071;
     border-radius: 0px;
}
</style>