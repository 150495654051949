import commons from './lang/commons.json'
import attributes from './lang/attributes.json'
import router from './lang/router.json'
import pages from './lang/pages.json'

const lang = {
     commons,
     attributes,
     router,
     pages,
}
export default lang