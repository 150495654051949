import apiClient from '@/plugins/apiClient'

export default {
     namespaced: true,
     state:{
          page: null,
          errors: {},
          message: null,
     },
     getters:{
          page: state => state.page,
          errors: state => state.errors,
          message: state => state.message,
     },
     mutations:{
          setPage(state,resp){
               console.log('mutation setPage', resp);
               state.page = resp.data
          },
          error(state, resp) {
               console.log('mutation setErrors',resp);
               state.errors = resp.errors
               state.message = resp.message
          },
          success(state, resp) {
               console.log('mutation success',resp);
               state.errors = {}
               state.message = null
          },
     },
     actions:{
          single({commit},data){
               var country = localStorage.getItem('language');

               return new Promise((resolve, reject) => {
                   console.log('GET page: ', [data,country])
                   apiClient.get("/pages/"+country+'/'+data).then(resp => {
                         commit('setPage', resp.data);
                         commit('success', resp.data);
                         resolve(resp);
                   })
                   .catch(err => {
                       console.log('GET PAGE ERROR', err)
                       commit('error', err.response.data);
                       reject(err);
                   });
               })
          },
     }
}
