<template>
     <div>
          <template v-if="snackbars.length >=1">
               <v-snackbar
                    top
                    left
                    elevation-10
                    fixed
                    v-for="(snack,n) in snackbars"
                    :key="n"
                    v-model="snack.visible"
                    :color="snack.type"
                    :style="'margin-top:'+(n*100)+'px'"
                    :timeout="snack.timeout+(n*10)"
                    @change="(snack.visible),removeSnackbar(n)"
                  
               >
               <div v-html="snack.message" class="font-weight-bold"></div>
               <div v-if="snack.text" v-html="snack.text"></div>
                    <template v-slot:action="{ attrs }">
                    <v-btn
                         dark
                         text
                         small
                         v-bind="attrs"
                         @click="removeSnackbar(n)"
                    >
                         x
                    </v-btn>
                    </template>
               </v-snackbar>
          </template>
     </div>
</template>
<script>
import {mapGetters} from "vuex";
export default {
     computed: {
          ...mapGetters({
                snackbars: 'snackBars/snackbars',
          }),
     },
     data: () => ({
    //
     }),
     watch: {
          snackbars:{
               deep: true,
               // We have to move our method to a handler field
               handler(list){
                    list.forEach((snack,n) => {
                         if(snack.visible===false){
                              list.splice(n, 1); 
                         }
                    });
               },
          }
     },
     methods:{
          removeSnackbar(n){
              this.snackbars[n].visible = false; 
          },
     }
}
</script>